<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Salesmen from "@/components/profile/brokers/salesmen/index.vue";
import AgencyBrokers from "@/components/profile/brokers/agency-brokers/index.vue";

import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.PROF.PRCO",
      tabs: [
        { name: "Salesmen", text: "Produttori" },
        { name: "AgencyBrokers", text: "Produttori di Agenzia" },
      ],
      tabsGroup: "Management",
      bcs: [
        {
          text: "Profilo",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Profilo",
          path: "",
          level: 1,
        },
        {
          text: "Anagrafica + Capitolato Produttori",
          path: "",
          level: 2,
        },
        {
          text: "Salesmen",
          path: "module.PROF.PRCO",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3, // use to identify the tabs level
      selected: "Salesmen",
      tabIndex: 0,
    };
  },
  components: {
    AgencyBrokers,
    Salesmen,
  },
};
</script>
